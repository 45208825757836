import "./MenuIcon.css";
import React, { useEffect, useRef } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Expo, TweenMax } from "gsap";

const useStyles = makeStyles(theme => ({
  root: {
    position: "absolute",
    right: "14px",
    top: "14px",
    zIndex: 100,
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      right: 0,
      top: 0,
      background: "white",
      padding: "14px"
    }
  },
  open: {
    background: "transparent"
  }
}));

const MenuIcon = ({ open, setOpen }) => {
  const classes = useStyles({});
  const menuRef = useRef();

  useEffect(function() {
    TweenMax.set(menuRef.current, {
      x: "100%"
    });
    TweenMax.to(menuRef.current, 1, { x: 0, ease: Expo.easeOut });
  }, []);

  return (
    <div className={`${classes.root} ${open ? classes.open : ""}`} ref={menuRef}>
      <div id="nav-icon-text" className={open ? "open" : "closed"}>
        MENU
      </div>
      <div
        id="nav-icon3"
        onClick={() => setOpen(!open)}
        className={open ? "open" : "closed"}
      >
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
};
export default MenuIcon;
