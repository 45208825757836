import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { colorPalette } from "./colors";
import { Link } from "gatsby";

const styles = theme => ({
  footer: {
    width: "100%",
    background: colorPalette.blue
  },

  container: {
    display: "grid",
    // gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
    // gridGap: '1.5vw',
    // maxWidth: '80%',
    height: "100%",
    margin: "auto",
    // padding: '5vh',
    // marginTop: '5vh',
    color: "white",

    // '& h2': {
    //   '&:first-of-type': {
    //     marginTop: 'auto',
    //   },
    //
    //   fontSize: '2rem',
    //
    //
    //   fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    //   fontWeight: '300',
    //   lineHeight: '0.8',
    //   letterSpacing: '-0.01562em',
    // },

    "& input": {
      width: "100%",
      padding: theme.spacing(1)
    }
  },

  middle: {
    "& ul": {
      margin: "40px",
      textAlign: "right",
      "& li": {
        listStyleType: "none",
        margin: theme.spacing(3),
        display: "inline",
        "& a": {
          color: "white"
        }
      }
    }
  }
});

const Footer = ({ classes }) => (
  <footer className={classes.footer}>
    <div className={classes.container}>
      <div className={classes.middle}>
        <ul>
          <li>
            <a href="https://decathlon.com.tr/">Decathlon Türkiye</a>
          </li>
          <li>
            <a href="https://blog.decathlon.com.tr/">Decathlon Blog</a>
          </li>
          <li>
            <Link to="/kvkk/">
              Kişisel Verilerin Korunması
            </Link>
          </li>
          <li>
            <Link to="/yasal-bildirimler/">
              Yasal Bildirimler
            </Link>
          </li>
        </ul>
      </div>
    </div>
  </footer>
);

export default withStyles(styles)(Footer);
