import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import "./layout.css";
import Footer from "./Footer";
import { withStyles } from "@material-ui/core";
import dckLogo from "../images/logo.png";
import { Link } from "gatsby";
import Menu from "./Menu";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/styles";
import theme from "../theme";
import { Expo, TweenMax } from "gsap";
import Cookies from "js-cookie";

import Button from "@material-ui/core/Button";
import * as Sentry from "@sentry/gatsby";

const styles = (theme) => ({
  homeLogo: {
    position: "absolute",
    zIndex: 10,
    top: 8, 
    left: 8,
    "&:hover": {
      transform: "scale(1.05)",
    },

    "& img": {
      width: "20rem",
    },
  },
  main: {
    height: "100vh",
    display: "grid",
    gridTemplateColumns: "1fr 60px",

    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr 0px",
    },
  },
  content: {
    overflowY: "scroll",
    overflowX: "hidden",
    margin: "10px",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("xs")]: {
      margin: "5px",
    },
  },
  filler: {
    flex: 1,
  },
  cookiePolicy: {
    position: "fixed",
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: "rgba(255, 255, 255, 0.9)",
    padding: "16px",
    zIndex: 1000,
    display: "flex",
  },
});
const COOKIE_NAME = "dkt-accepted-cookies";

const Layout = ({ classes, children }) => {
  const logoRef = useRef();
  const [open, setOpen] = useState(false);

  useEffect(function () {
    setOpen(Cookies.get(COOKIE_NAME) === undefined);
    TweenMax.set(logoRef.current, {
      y: "-100%",
    });
    TweenMax.to(logoRef.current, 1, { y: 0, ease: Expo.easeOut });
  }, []);
  return (
    <>
      <Sentry.ErrorBoundary fallback={"Bir hata oluştu"}>
        <ThemeProvider theme={theme}>
          <CssBaseline />

          <div className={classes.main}>
            <div className={classes.content}>
              <Link to="/" className={classes.homeLogo} ref={logoRef}>
                <img src={dckLogo} alt="Decathlon Logo" />
              </Link>
              {children}
              <div className={classes.filler}></div>
              <Footer />
            </div>
            <Menu />
          </div>
          {open && (
            <div className={classes.cookiePolicy}>
              <p>
                İnternet sitemizden en verimli şekilde faydalanabilmeniz ve kullanıcı deneyimini geliştirebilmek için
                internet sitemizde çerezler kullanılmaktadır. Çerez kullanımını kabul edebilir, ayarlarınızdan çerezleri
                silebilir veya engelleyebilirsiniz. Çerezler hakkında detaylı bilgi almak için
                <a
                  href="https://kesfet.decathlon.com.tr/cdn/cookie-policy.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  İnternet Sitesi Çerez Politikası
                </a>
                ’nı incelemenizi rica ederiz.
              </p>
              <Button
                color="secondary"
                size="small"
                onClick={() => {
                  Cookies.set(COOKIE_NAME, "true");
                  setOpen(false);
                }}
              >
                KABUL EDİYORUM
              </Button>
            </div>
          )}
        </ThemeProvider>
      </Sentry.ErrorBoundary>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default withStyles(styles)(Layout);
