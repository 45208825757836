import React from "react";
import ButtonBase from "@material-ui/core/ButtonBase";
import { makeStyles } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
  button: props => ({
    color: "#fff",
    background: theme.palette.secondary.main,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(props.spacing),
    paddingRight: theme.spacing(props.spacing),
    position: "relative",
    minHeight: theme.spacing(6),
    "&:hover": {
      "& $arrow": {
        right: theme.spacing(props.spacing - 1)
      }
    }
  }),
  arrow: props => ({
    position: "absolute",
    bottom: theme.spacing(1),
    right: theme.spacing(props.spacing),
    transition: `right 0.2s linear`
  })
}));

export default function ArrowButton({
                                      text,
                                      onClick = undefined,
                                      to = undefined,
                                      spacing = 2
                                    }) {
  const classes = useStyles({ spacing });
  return (
    <ButtonBase
      focusRipple
      onClick={onClick}
      href={to}
      className={classes.button}
    >
      <Typography variant="subtitle2" align="right">{text}</Typography>
      <ArrowForwardIcon fontSize="small" className={classes.arrow}/>
    </ButtonBase>
  );
}
