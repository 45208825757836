import React, { useEffect, useRef, useState } from "react";
import { withStyles } from "@material-ui/core";
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter, FaYoutube } from "react-icons/fa";
import gptw from "../images/gptw.png";
import { colorPalette } from "./colors";
import { IconContext } from "react-icons";
import ArrowButton from "../util/ArrowButton";
import MenuOpen from "./MenuOpen";
import { Expo, TweenMax } from "gsap";

const styles = (theme) => ({
  sideNav: {
    height: "100%",
    width: "60px",
    position: "fixed",
    zIndex: 2 /* Stay on top */,
    top: 0 /* Stay at the top */,
    right: 0,
    backgroundColor: "#F2F3F4" /* Black */,
    paddingTop: "20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },

  filler: {
    flexGrow: 1,
  },

  social: {
    width: "2vw",
    height: "2vw",
    display: "inline",
    color: "#D2D3D5",
    marginBottom: "1vw",

    transition: "color 0.5s ease",

    "&:hover": {
      color: colorPalette.blue,
      cursor: "pointer",
    },
  },

  gptw: {
    width: "100%",
  },

  openPositions: {
    position: "fixed",
    top: "10vh",
    right: 0,
  },
});

const Menu = ({ classes }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef();

  useEffect(function () {
    TweenMax.set(menuRef.current, {
      x: "100%",
    });
    TweenMax.to(menuRef.current, 1, { x: 0, ease: Expo.easeOut });
  }, []);

  return (
    <IconContext.Provider value={{ className: classes.social }}>
      <MenuOpen open={menuOpen} setOpen={setMenuOpen} />
      <div className={classes.sideNav} ref={menuRef}>
        <div className={classes.openPositions}>
          <ArrowButton
            to="/ilanlar/"
            text={
              <>
                Açık
                <br />
                Pozisyonlarımız
              </>
            }
            spacing={1}
          />
        </div>

        <div className={classes.filler} />

        <a href="https://linkedin.com/company/DecathlonTurkey" rel="noopener noreferrer" target="_blank">
          <FaLinkedin />
        </a>
        <a href="https://www.facebook.com/decathlonturkeykariyer/" rel="noopener noreferrer" target="_blank">
          <FaFacebook />
        </a>
        <a href="https://www.instagram.com/decathlonkariyer" rel="noopener noreferrer" target="_blank">
          <FaInstagram />
        </a>
        <a href="https://twitter.com/DecathlonTurkey" rel="noopener noreferrer" target="_blank">
          <FaTwitter />
        </a>
        <a href="https://youtube.com/user/DecathlonTurkiye" rel="noopener noreferrer" target="_blank">
          <FaYoutube />
        </a>
      </div>
    </IconContext.Provider>
  );
};

export default withStyles(styles)(Menu);
