const orange = "#EA6608";
const blue = "#097DC9";
export const colorPalette = {
  blue,
  orange,
  blueWithAlpha: alpha => `${blue}${alpha}`,
  orangeWithAlpha: alpha => `${orange}${alpha}`,
  red: "#E40002",
  grey: "#F2F3F4",
  darkGrey: "#D2D3D5",
  black: "#424549"
};
