import { Link } from "gatsby";
import yazi from "../images/home/yazi.png";
import React, { useEffect, useRef } from "react";
import { colorPalette } from "./colors";
import menuBg from "../images/menu_bg.jpeg";
import { colors, makeStyles } from "@material-ui/core";
import { Expo, TweenMax } from "gsap";
import MenuIcon from "./MenuIcon";

const useStyles = makeStyles(theme => ({
  menuOpen: {
    display: "grid",
    height: "100vh",
    width: "100vw",
    position: "absolute",
    zIndex: 20 /* Stay on top */,
    top: 0 /* Stay at the top */,
    left: 0,
    gridTemplateColumns: "1fr 1fr",
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr"
    }
  },

  left: {
    backgroundColor: colorPalette.orangeWithAlpha("F0"),
    height: 0,
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  },

  right: {
    height: 0,
    background: `url(${menuBg})`,
    backgroundPosition: "right",
    display: "flex",
    justifyContent: "center",
    alignItems: "start",
    transition: "color 0.5s ease",
    "& ul": {
      listStyle: "none",
      zIndex: 1000
    },
    "& h2": {
      color: "white",
      fontWeight: 500,
      fontSize: "3.5vh",
      transition: "color 0.5s linear",
      "&:hover": {
        color: colors.orange[500]
      }
    },

    "& h3": {
      color: "white",
      fontWeight: 500,
      fontSize: "2vh",
      transition: "color 0.5s linear",
      "&:hover": {
        color: colors.orange[500]
      }
    }
  },

  decathlon: {
    position: "absolute",
    bottom: "0",
    marginBottom: "-20px",
    width: "50%",
    paddingLeft: "1%",
    paddingRight: "1%",
    filter: "invert(0.3)",

    opacity: "0.1",
    [theme.breakpoints.down("xs")]: {
      width: "50%"
    }
  }
}));

export default function MenuOpen({ open, setOpen }) {
  const classes = useStyles();
  const leftRef = useRef();
  const rightRef = useRef();
  const menuRef = useRef();

  useEffect(
    function() {
      TweenMax.set(menuRef.current.childNodes, {
        delay: 0.5,
        opacity: 0,
        x: "30%"
      });
      if (open) {

        TweenMax.to(leftRef.current, 0.4, { height: "100vh" });
        TweenMax.to(rightRef.current, 0.4, {
          delay: 0.2,
          height: "100vh"
        });

        TweenMax.staggerTo(
          menuRef.current.childNodes,
          0.5,
          {
            delay: 1,
            opacity: 1,
            ease: Expo.easeOut,
            x: 0
          },
          0.05
        );
      }
    },
    [open]
  );

  const handleClose = () => {
    TweenMax.staggerTo(
      menuRef.current.childNodes,
      0.4,
      {
        ease: Expo.easeOut,
        opacity: 0,
        x: "30%"
      },
      0.1
    );
    TweenMax.to(rightRef.current, 0.4, { delay: 0.7, height: 0 });
    TweenMax.to(leftRef.current, 0.4, {
      delay: 0.9,
      height: 0,
      onComplete: () => {
        setOpen(false);
      }
    });
  };

  const handleChange = open => {
    if (open) {
      setOpen(true);
    } else {
      handleClose();
    }
  };

  return (
    <>
      <MenuIcon open={open} setOpen={handleChange}/>
      <div
        className={classes.menuOpen}
        style={{ display: open ? "grid" : "none" }}
      >
        <div
          ref={leftRef}
          className={`${classes.left} ${classes.filler}`}
          onClick={handleClose}
        />
        <div className={`${classes.right} ${classes.filler}`} ref={rightRef}>
          <ul onClick={handleClose} ref={menuRef}>
            <li className={classes.link}>
              <Link to="/">
                <h2>ANA SAYFA</h2>
              </Link>
            </li>
            <li>
              <h2>BİZ KİMİZ?</h2>
            </li>
            <ul>
              <li className={classes.link}>
                <Link to="/biz-kimiz/vizyonumuz/">
                  <h3>VİZYONUMUZ</h3>
                </Link>
              </li>
              <li className={classes.link}>
                <Link to="/biz-kimiz/amacimiz/">
                  <h3>AMACIMIZ</h3>
                </Link>
              </li>
              <li className={classes.link}>
                <Link to="/biz-kimiz/degerlerimiz/">
                  <h3>DEĞERLERİMİZ</h3>
                </Link>
              </li>
              <li className={classes.link}>
                <Link to="/biz-kimiz/surdurulebilirlik/">
                  <h3>SÜRDÜRÜLEBİLİRLİK</h3>
                </Link>
              </li>
              <li className={classes.link}>
                <Link to="/biz-kimiz/tarihcemiz/">
                  <h3>TARİHÇEMİZ</h3>
                </Link>
              </li>
            </ul>
            <li className={classes.link}>
              <Link to="/rollerimiz/">
                <h2>ROLLERİMİZ</h2>
              </Link>
            </li>
            <li className={classes.link}>
              <Link to="/ilanlar/">
                <h2>AÇIK POZİSYONLARIMIZ</h2>
              </Link>
            </li>
            <li className={classes.link}>
              <Link to="/nerelerdeyiz/">
                <h2>NERELERDEYİZ</h2>
              </Link>
            </li>
            <li className={classes.link}>
              <Link to="/yan-haklarimiz/">
                <h2>YAN HAKLARIMIZ</h2>
              </Link>
            </li>

            {/*<li className={classes.link}><Link to="/etkinlikler"><h2>ETKİNLİKLER</h2></Link></li>*/}
          </ul>
          <img alt="" src={yazi} className={classes.decathlon}/>
        </div>
      </div>
    </>
  );
}
