/* eslint-disable no-underscore-dangle */

import { createMuiTheme } from "@material-ui/core/styles";

// A theme with custom primary and secondary color.
// It's optional.
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#097DC9"
    },
    secondary: {
      main: "#ec6607"
    },
    text: {
      primary: "#646F74",
      secondary: "#7E868C"
    }
  },
  shape: {
    borderRadius: 0
  },
  typography: {
    // fontFamily: "FuturaPT",
    // allVariants: {
    //   fontFamily: "FuturaPT"
    // },
    useNextVariants: true,
    h2: {
      color: "#646F74"
    }
  }
});
// const { breakpoints } = defaultTheme;
//
// const theme = {
//   ...defaultTheme,
//   overrides: {
//     MuiTypography: {
//       h1: {
//         textAlign: "center",
//         fontSize: "5rem",
//         [breakpoints.down("sm")]: {
//           fontSize: "3rem"
//         }
//       }
//     }
//   }
// };
export default theme;
